import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import PageNotFound from '../modules/404-page/PageNotFound';
import AddedCarts from '../modules/added-carts/AddedCarts';
import Banner from '../modules/banner/Banner';
import Configurations from '../modules/configurations/Configurations';
import CtaBanners from '../modules/cta-banners/CtaBanners';
import CustomerAppNotifications from '../modules/customers/CustomerAppNotifications';
import CustomerDetail from '../modules/customers/CustomerDetail';
import CustomerOrderHistory from '../modules/customers/CustomerOrderHistory';
import Customers from '../modules/customers/Customers';
import BookedCustomers from '../modules/dashboard/BookedCustomers';
import Dashboard from '../modules/dashboard/Dashboard';
import DeepLinkMonitor from '../modules/deep-link-monitor/DeepLinkMonitor';
import AllDoctors from '../modules/doctors-tab/AllDoctors';
import DoctorDetails from '../modules/doctors-tab/DoctorDetails';
import DoctorsReport from '../modules/doctors-tab/DoctorsReport';
import LabReports from '../modules/lab reports/LabReports';
import Labs from '../modules/labs/Labs';
import OfferDetails from '../modules/offers/OfferDetails';
import Offers from '../modules/offers/Offers';
import Pincodes from '../modules/pincodes/Pincodes';
import Prescriptions from '../modules/prescriptions/Prescriptions';
import Reports from '../modules/reports/Reports';
import OrderReport from '../modules/reports/components/orders/OrderReport';
import OrderTransactions from '../modules/reports/components/orders/OrderTransactions';
import DiseaseCategories from '../modules/risk-assessment/components/DiseaseCategories';
import DiseaseQuestions from '../modules/risk-assessment/components/DiseaseQuestions';
import Diseases from '../modules/risk-assessment/components/Diseases';
import RuleBased from '../modules/ruleBased/RuleBased';
import Samples from '../modules/samples/Samples';
import MessageDetails from '../modules/send-message/MessageDetails';
import Messages from '../modules/send-message/Messages';
import TemplateAddress from '../modules/template-address/TemplateAddress';
import Testimonials from '../modules/testimonials/Testimonials';
import TestSamples from '../modules/tests/components/Test-Samples/TestSamples';
import TestAddons from '../modules/tests/components/addons/TestAddons';
import AllTests from '../modules/tests/components/all-tests/AllTests';
import TestCategories from '../modules/tests/components/categories/TestCategories';
import TestEquipments from '../modules/tests/components/equipments/TestEquipments';
import TestCustomersList from '../modules/tests/components/test-customer-list/TestCustomerList';
import TimeSheet from '../modules/time-sheet/TimeSheet';
import TimelyOffers from '../modules/timely-offers/TimelyOffers';
import UniversalCarts from '../modules/universal-carts/UniversalCarts';
import Users from '../modules/users/Users';

function ContentRoutes({ user }) {
  if (user && user.role === 'ADMIN') {
    // For ADMIN role, only allow access to these routes
    return (
      <Routes>
        <Route
          exact
          path={ROUTES.MAIN}
          element={<Navigate replace to="/customers/1" />}
        />
        <Route exact path={ROUTES.CUSTOMERS} element={<Customers />} />
        <Route
          exact
          path={ROUTES.CUSTOMER_DETAIL}
          element={<CustomerDetail />}
        />
        <Route
          exact
          path={ROUTES.CUSTOMER_ORDER_HISTORY}
          element={<CustomerOrderHistory />}
        />
        <Route exact path={ROUTES.TESTIMONIALS} element={<Testimonials />} />
        <Route exact path={ROUTES.MESSAGES} element={<Messages />} />
        <Route
          exact
          path={ROUTES.MESSAGE_DETAILS}
          element={<MessageDetails />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    );
  }

  let isLab = false;
  if (user && user.role === 'OPERATOR') {
    isLab = true;
  }
  if (isLab)
    return (
      <Routes>
        <Route exact path={ROUTES.MAIN} element={<Samples />} />
        <Route exact path={ROUTES.LAB_REPORTS} element={<LabReports />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    );

  return (
    <Routes>
      <Route exact path={ROUTES.MAIN} element={<Dashboard />} />
      <Route
        exact
        path={ROUTES.BOOKED_CUSTOMERS}
        element={<BookedCustomers />}
      />

      <Route exact path={ROUTES.CUSTOMERS} element={<Customers />} />
      <Route exact path={ROUTES.CUSTOMER_DETAIL} element={<CustomerDetail />} />
      <Route
        exact
        path={ROUTES.CUSTOMER_ORDER_HISTORY}
        element={<CustomerOrderHistory />}
      />
      <Route
        exact
        path={ROUTES.CUSTOMER_APP_NOTIFICATIONS}
        element={<CustomerAppNotifications />}
      />
      <Route exact path={ROUTES.OFFERS} element={<Offers />} />
      <Route exact path={ROUTES.OFFERS_DETAILS} element={<OfferDetails />} />
      <Route exact path={ROUTES.USERS} element={<Users />} />
      <Route exact path={ROUTES.REPORTS} element={<Reports />} />
      <Route exact path={ROUTES.TESTIMONIALS} element={<Testimonials />} />
      <Route exact path={ROUTES.TIME_SHEET} element={<TimeSheet />} />
      <Route exact path={ROUTES.PRESCRIPTIONS} element={<Prescriptions />} />
      <Route exact path={ROUTES.MESSAGES} element={<Messages />} />
      <Route exact path={ROUTES.MESSAGE_DETAILS} element={<MessageDetails />} />
      <Route exact path={ROUTES.ADDED_CARTS} element={<AddedCarts />} />
      <Route
        exact
        path={ROUTES.DISEASE_CATEGORIES}
        element={<DiseaseCategories />}
      />
      <Route exact path={ROUTES.DISEASES} element={<Diseases />} />
      <Route
        exact
        path={ROUTES.DISEASE_QUESTIONS}
        element={<DiseaseQuestions />}
      />
      <Route exact path={ROUTES.CONFIGURATIONS} element={<Configurations />} />
      <Route exact path={ROUTES.LABS} element={<Labs />} />
      <Route
        exact
        path={ROUTES.TEMPLATE_ADDRESS}
        element={<TemplateAddress />}
      />
      <Route exact path={ROUTES.UNIVERSAL_CARTS} element={<UniversalCarts />} />
      <Route
        exact
        path={ROUTES.DEEP_LINK_MONITOR}
        element={<DeepLinkMonitor />}
      />

      {/* HOME ROUTES */}
      <Route exact path={ROUTES.BANNERS} element={<Banner />} />
      <Route exact path={ROUTES.CTA_BANNERS} element={<CtaBanners />} />
      <Route exact path={ROUTES.TIMELY_OFFERS} element={<TimelyOffers />} />
      <Route exact path={ROUTES.RULE_BASED} element={<RuleBased />} />
      <Route exact path={ROUTES.PIN_CODES} element={<Pincodes />} />
      {/* TEST ROUTES */}
      <Route exact path={ROUTES.ALL_TESTS} element={<AllTests />} />
      <Route
        exact
        path={ROUTES.TESTED_CUSTOMERS}
        element={<TestCustomersList />}
      />
      <Route exact path={ROUTES.CATEGORIES} element={<TestCategories />} />
      <Route exact path={ROUTES.EQUIPMENTS} element={<TestEquipments />} />
      <Route exact path={ROUTES.SAMPLES} element={<TestSamples />} />
      <Route exact path={ROUTES.ADDONS} element={<TestAddons />} />
      {/* REPORT ROUTES */}
      <Route exact path={ROUTES.REPORT_DETAIL} element={<OrderReport />} />
      <Route
        exact
        path={ROUTES.ORDER_TRANSACTION}
        element={<OrderTransactions />}
      />
      {/* DOCTOR TAB ROUTES */}
      <Route exact path={ROUTES.DOCTORS} element={<AllDoctors />} />
      <Route exact path={ROUTES.DOCTOR_DETAILS} element={<DoctorDetails />} />
      <Route exact path={ROUTES.DOCTOR_REPORTS} element={<DoctorsReport />} />
      {/* LAB ROUTES */}
      <Route exact path={ROUTES.MAIN} element={<Samples />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default ContentRoutes;
